import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InteracETransferRequest from './InteracETransferRequest';
import GetBalance from './GetBalance';
import SearchRequestInteracEtransfer from './SearchRequestInteracEtransfer';

const Dashboard = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState('balance'); // Default active component
  const [isMobile, setIsMobile] = useState(false); // State to check if device is mobile
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to control sidebar visibility

  useEffect(() => {
    const setWindowWidth = () => {
      setIsMobile(window.innerWidth <= 768); // Define mobile as screen width <= 768px
    };

    setWindowWidth(); // Initial check
    window.addEventListener('resize', setWindowWidth); // Listen for window resize events
    return () => {
      window.removeEventListener('resize', setWindowWidth); // Remove event listener on component unmount
    };
  }, []);

  const handleLogout = () => {
    setIsLoggedIn(false);
    navigate('/');
  };

  const handleNavItemClick = (component) => {
    setActiveComponent(component);
    if (isMobile) setIsSidebarOpen(false); // Close sidebar on mobile after navigation
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="container-fluid vh-100">
      {/* Top bar with welcome message */}
      <div className="bg-primary text-white py-3 px-4">
        <h3>Welcome to the Dashboard!</h3>
      </div>

      <div className="row">
        {/* Left sidebar */}
        <div className={`col-md-3 ${isMobile && !isSidebarOpen ? 'd-none' : 'd-md-block'} bg-light`}>
          <nav className="navbar navbar-expand-md navbar-light">
            <div className="container-fluid">
              <button className="navbar-toggler" type="button" onClick={toggleSidebar}>
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={`collapse navbar-collapse ${isMobile ? (isSidebarOpen ? 'show' : '') : 'show'}`} id="navbarNav">
                <ul className="navbar-nav flex-column">
                  <li className="nav-item">
                    <button className={`nav-link ${activeComponent === 'interac' ? 'active' : ''} btn btn-outline-primary rounded-0 w-100 text-start`} onClick={() => handleNavItemClick('interac')}>Interac eTransfer Request</button>
                  </li>
                  <li className="nav-item">
                    <button className={`nav-link ${activeComponent === 'balance' ? 'active' : ''} btn btn-outline-success rounded-0 w-100 text-start`} onClick={() => handleNavItemClick('balance')}>Merchant Balance Information</button>
                  </li>
                  <li className="nav-item">
                    <button className={`nav-link ${activeComponent === 'searchrequestinteracetransfer' ? 'active' : ''} btn btn-outline-success rounded-0 w-100 text-start`} onClick={() => handleNavItemClick('searchrequestinteracetransfer')}>Search Request Interac Etransfer</button>
                  </li>
                  <li className="nav-item">
                    <button className="btn btn-outline-danger rounded-0 w-100 text-start" onClick={handleLogout}>Logout</button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>

        {/* Main content area */}
        <div className="col-md-9">
          <div className="container py-4">
            {/* Tab navigation */}
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button className={`nav-link ${activeComponent === 'interac' ? 'active' : ''}`} style={{ backgroundColor: activeComponent === 'interac' ? '#f2f2f2' : '' }} onClick={() => handleNavItemClick('interac')}>Interac eTransfer Request</button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${activeComponent === 'balance' ? 'active' : ''}`} style={{ backgroundColor: activeComponent === 'balance' ? '#f2f2f2' : '' }} onClick={() => handleNavItemClick('balance')}>Merchant Balance Information</button>
              </li>
              <li className="nav-item">
                <button className={`nav-link ${activeComponent === 'searchrequestinteracrtransfer' ? 'active' : ''}`} style={{ backgroundColor: activeComponent === 'searchrequestinteracrtransfer' ? '#f2f2f2' : '' }} onClick={() => handleNavItemClick('searchrequestinteracrtransfer')}>Search Request Interac Etransfer</button>
              </li>
            </ul>

            {/* Render active component based on state */}
            {activeComponent === 'interac' && <InteracETransferRequest />}
            {activeComponent === 'balance' && <GetBalance />}
            {activeComponent === 'searchrequestinteracrtransfer' && <SearchRequestInteracEtransfer />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
