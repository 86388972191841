import cryptoJS from "crypto-js";

export const requestInteracETransferLink = async (formData, apiKey, sharedSecret) => {
  const generateSignature = (apiKey, sharedSecret) => {
    const current_date = new Date().toISOString().split("T")[0];
    const concatedString = apiKey + sharedSecret + current_date;
    const cjsHash = cryptoJS.SHA512(concatedString);
    return cjsHash.toString(cryptoJS.enc.Base64);
  };

  const signature = generateSignature(apiKey, sharedSecret);

  const myHeaders = new Headers();
  myHeaders.append("Key", apiKey);
  myHeaders.append("Signature", signature);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(formData),
    redirect: "follow"
  };

  try {
    const response = await fetch("https://sandboxapi.apaylo.com/sandboxapi.apaylo.com/api/Merchant/RequestInteracEtransferLink", requestOptions);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const result = await response.json(); // Parse JSON response
    const referenceNumber = result.Result.ReferenceNumber; // Extract ReferenceNumber from response
    return referenceNumber; // Return only the ReferenceNumber
  } catch (error) {
    console.error('Error fetching data:', error);
    throw new Error('Failed to fetch payee data');
  }
};
