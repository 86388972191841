import React, { useState, useEffect } from 'react';
import cryptoJS from "crypto-js";

const SearchRequestInteracEtransfer = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]); // Set today's date as default
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false); // State to track loading state

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
  };

  useEffect(() => {
    // Fetch and set recent transactions when component mounts
    fetchRecentTransactions();
  }, []);

  const fetchRecentTransactions = async () => {
    try {
      setLoading(true);
      // Your API call to fetch recent transactions goes here
      const recentTransactions = []; // Replace this with your API call to fetch transactions
      setTransactions(recentTransactions.slice(0, 5)); // Displaying only the first 5 recent transactions
    } catch (error) {
      console.error('Error fetching recent transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Client-side validation
    if (!startDate || !endDate) {
      alert('Please fill in all fields');
      return;
    }
    
    const formData = {
      StartDate: startDate,
      EndDate: endDate
    };
    
    const apiKey = "9108899b09cdd9a95f4994740e2dab5a4b1b2dc4"; // API key
    const sharedSecret = "ym1ULECimHYT9KqgZesrGA=="; // Shared secret

    const generateSignature = (apiKey, sharedSecret) => {
      const current_date = new Date().toISOString().split("T")[0];
      const concatedString = apiKey + sharedSecret + current_date;
      const cjsHash = cryptoJS.SHA512(concatedString);
      return cjsHash.toString(cryptoJS.enc.Base64);
    };
    
    const signature = generateSignature(apiKey, sharedSecret);

    const myHeaders = new Headers();
    myHeaders.append("Key", apiKey);
    myHeaders.append("Signature", signature);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(formData),
    };

    try {
      setLoading(true); // Set loading to true when fetching data
      const response = await fetch('https://sandboxapi.apaylo.com/sandboxapi.apaylo.com/api/Merchant/SearchRequestInteracEtransfer', requestOptions);
      if (response.ok) {
        const data = await response.json();
        // Handle response data
        console.log(data.Result);
        setTransactions(data.Result); // Set transactions state with the response data
      } else {
        // Handle error response
        console.error('Request failed with status:', response.status);
      }
    } catch (error) {
      // Handle network errors
      console.error('Request failed:', error.message);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    if (transactions.length > 0) {
      const sortedTransactions = [...transactions].sort((a, b) => new Date(b.TransactionDate) - new Date(a.TransactionDate));
      if (JSON.stringify(transactions) !== JSON.stringify(sortedTransactions)) { // Check if sorting is needed
        setTransactions(sortedTransactions);
      }
    }
  }, [transactions]); // Depend on transactions
  

  return (
    <div class="bg-light py-4 px-3 mt-4">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="startDate" className="form-label">Start Date</label>
            <input type="date" className="form-control" id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="endDate" className="form-label">End Date</label>
            <input type="date" className="form-control" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} required />
          </div>
        </div>
        <button type="submit" className="btn btn-primary">Search Interac eTransfer</button>
      </form>
      <div className="mt-3">
        {loading && <div className="loader"></div>} {/* Show loader when loading is true */}
        {!loading && transactions.length > 0 && (
          <div>
            <h5>Recent Transactions:</h5>
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Customer Email</th>
                  <th>Interac Reference Number</th>
                  <th>Amount</th>
                  <th>Transaction Status</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction, index) => (
                  <tr key={index}>
                    <td>{formatDate(transaction.TransactionDate)}</td>
                    <td>{transaction.CustomerEmail}</td>
                    <td>{transaction.InteracReferenceNumber}</td>
                    <td>{transaction.Amount}</td>
                    <td>{transaction.TransactionStatus}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchRequestInteracEtransfer;
