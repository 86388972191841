import React, { useState, useEffect } from 'react';
import cryptoJS from 'crypto-js';
import './styles.css'; // Importing the CSS file

const GetBalance = () => {
  // Sample API key and shared secret values
  const [apiKey] = useState('9108899b09cdd9a95f4994740e2dab5a4b1b2dc4');
  const [sharedSecret] = useState('ym1ULECimHYT9KqgZesrGA==');

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [balanceData, setBalanceData] = useState(null);

  useEffect(() => {
    const fetchBalanceData = async () => {
      try {
        const currentDate = new Date().toISOString().split("T")[0];
        const concatedString = apiKey + sharedSecret + currentDate;
        const signature = cryptoJS.SHA512(concatedString).toString(cryptoJS.enc.Base64);

        const response = await fetch('https://sandboxapi.apaylo.com/sandboxapi.apaylo.com/api/Merchant/GetBalance', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Key': apiKey,
            'Signature': signature
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch balance data');
        }

        const data = await response.json();
        setBalanceData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBalanceData();
  }, [apiKey, sharedSecret]);

  return (
    <div className="container">
      <div className="header">
        <h1>Merchant Balance</h1>
      </div>
      <div className="content">
        {isLoading ? (
          <div className="loading">Loading...</div>
        ) : error ? (
          <div className="error">Error: {error}</div>
        ) : (
          <div className="balance-info">
            <div className="balance-box" style={{ backgroundColor: '#F8D7DA' }}>
              <p>Available Balance: {balanceData && balanceData.Result.AvailableBalance}</p>
            </div>
            <div className="balance-box" style={{ backgroundColor: '#D4EDDA' }}>
              <p>Actual Balance: {balanceData && balanceData.Result.ActualBalance}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GetBalance;
