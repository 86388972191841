import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';

const ApayloAPIReference = ({ apiKey, sharedSecret, referenceNumber }) => {
  const [qrCodeData, setQRCodeData] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    // Generate QRCode data with dynamic URL including referenceNumber
    const apiUrl = `https://gateway-web.fit.interac.ca/acceptPaymentRequest.do?rID=${referenceNumber}`;
    QRCode.toDataURL(apiUrl)
      .then(dataUrl => {
        setQRCodeData(dataUrl);
        setError(null);
      })
      .catch(err => {
        setError(err.message);
      });
  }, [referenceNumber]);

  return (
    <div>
      {error && <p>Error: {error}</p>}
      {qrCodeData && (
        <div>
          <h2>Scan QR Code to request payment:</h2>
          <img src={qrCodeData} alt="Apaylo API QR Code" />
        </div>
      )}
    </div>
  );
};

export default ApayloAPIReference;
