import React, { useState } from 'react';

const InteracETransferRequestForm = ({ onSubmit }) => {
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Client-side validation
    if (!customerName || !customerEmail || !amount || !description) {
      alert('Please fill in all fields');
      return;
    }
    const formData = {
      CustomerName: customerName,
      CustomerEmail: customerEmail,
      Amount: parseFloat(amount), // Convert amount to float
      Description: description
    };
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="customerEmail" className="form-label">Enter Valid Customer Email</label>
          <input type="email" className="form-control" id="customerEmail" value={customerEmail} onChange={(e) => setCustomerEmail(e.target.value)} placeholder="Customer Email" required />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="customerName" className="form-label">Enter Valid Customer Name</label>
          <input type="text" className="form-control" id="customerName" value={customerName} onChange={(e) => setCustomerName(e.target.value)} placeholder="Customer Name" required />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="amount" className="form-label">Enter an Amount</label>
          <input type="number" className="form-control" id="amount" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="Amount" required />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="description" className="form-label">Enter Description</label>
          <input type="text" className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" required />
        </div>
      </div>
      <button type="submit" className="btn btn-primary">Request Interac eTransfer</button>
    </form>
  );
};

export default InteracETransferRequestForm;
