import React, { useState } from 'react';
import InteracETransferRequestForm from './InteracETransferRequestForm';
import ApayloAPIReference from './ApayloAPIReference'; // Import ApayloAPIReference component
import { requestInteracETransferLink } from './api'; 

const InteracETransferRequest = () => {
  const [referenceNumber, setReferenceNumber] = useState(null);
  const [apiKey] = useState("9108899b09cdd9a95f4994740e2dab5a4b1b2dc4"); // API key
  const [sharedSecret] = useState("ym1ULECimHYT9KqgZesrGA=="); // Shared secret

  const handleFormSubmit = async (formData) => {
    try {
      const result = await requestInteracETransferLink(formData, apiKey, sharedSecret);
      console.log(result); // Log the response data
      setReferenceNumber(result); // Assuming the result is the reference number
    } catch (error) {
      console.error('Error requesting Interac eTransfer:', error);
    }
  };

  return (
    <div class="bg-light py-4 px-3 mt-4">
      <h2 class="py-2">Request Interac eTransfer</h2>
      {referenceNumber ? (
        <div>
          <p>
            Interac eTransfer requested successfully. Your Reference number is: {referenceNumber}
          </p>
          {/* Render ApayloAPIReference component upon successful form submission */}
          <ApayloAPIReference referenceNumber={referenceNumber} />
        </div>
      ) : (
        <InteracETransferRequestForm onSubmit={handleFormSubmit} />
      )}
    </div>
  );
};

export default InteracETransferRequest;
